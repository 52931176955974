<template>
  <div class="center_submit">
    <section class="section_submit center_sec">
      <h4>1:1 문의</h4>
      <slot name="forms"></slot>
      <slot name="button"></slot>
    </section>
  </div>
</template>

<script>
export default {
  name: "CenterSubmit",
}
</script>
