<template>
  <div id="center" class="sub_wrap">
    <LayerPopup v-if="terms" :popId="'termsUsed'">
      <template v-slot:popContents>
        <div class="contents">
          <h4>이용약관</h4>
          <div class="doc_wrap">
            <pre>
              1:1문의 이용약관
							1. 수집 정보 ㈜데이터유니버스는 이용자의 원활한 고객상담 및 불만처리를 위하여 아래와 같은 정보를 수집 합니다.
							* 수집 정보 : 이름, 연락처, 이메일 주소, 고객의 문의/답변 내용에 포함된 정보
							2. 수집 및 이용 목적
							수집된 정보는 고객문의 및 상담요청에 대하여 회신을 하거나, 상담을 위한 서비스 이용기록 조회를 목적으로 활용합니다.
							3. 보관 및 이용 기간
							이용자의 정보는 문의 처리 이후 3년간 보관 후 파기됩니다.
            </pre>
          </div>
          <ul class="pop_btns">
            <li><button type="button" class="pop_btn access" @click="viewTerms">확인</button></li>
          </ul>
        </div>
      </template>
    </LayerPopup>
    <section class="sub_sec">
      <CenterSubmit>
        <template v-slot:forms>
          <div class="forms_wrap">
            <strong class="forms_tit">문의유형</strong>
            <span class="forms_select">
              <select
                name="case_question"
                id="case_question"
                :class="center.caseQ && 'selected'"
                v-model="center.caseQ"
              >
                <option value="" selected disabled hidden>문의유형을 선택해 주세요.</option>
                <option value="서비스가입">서비스가입</option>
                <option value="서비스해지">서비스해지</option>
                <option value="이용문의">이용문의</option>
                <option value="제휴문의">제휴문의</option>
                <option value="기타">기타</option>
              </select>
            </span>
          </div>
          <div class="forms_wrap">
            <strong class="forms_tit">성명</strong>
            <span class="forms_ip">
              <input type="text" maxlength="10" placeholder="성명을 입력해주세요." v-model="center.PName" />
            </span>
          </div>
          <div class="forms_wrap">
            <strong class="forms_tit">연락처</strong>
            <span class="forms_ip">
              <input
                type="number"
                maxlength="11"
                placeholder="연락처를 입력해주세요."
                v-model="center.PNumber"
                @input="insertLengthCheck(center.PNumber, 11, 'CHG_PNUM')"
              />
            </span>
          </div>
          <div class="forms_wrap">
            <strong class="forms_tit">이메일</strong>
            <span class="forms_ip">
              <input type="text" maxlength="30" placeholder="이메일을 입력해주세요." v-model="center.Email" />
            </span>
          </div>
          <div class="forms_wrap">
            <strong class="forms_tit">제목</strong>
            <span class="forms_ip">
              <input type="text" maxlength="20" placeholder="제목을 입력해주세요." v-model="center.Title" />
            </span>
          </div>
          <div class="forms_wrap textarea">
            <strong class="forms_tit"
              >문의내용
              <div class="conts_count">{{ contsCount }}/150</div></strong
            >
            <span class="forms_ip">
              <textarea
                type="text"
                maxlength="150"
                v-model="center.Contents"
                placeholder="내용을 입력해주세요"
              ></textarea>
            </span>
          </div>
          <div class="forms_wrap checkbox">
            <span class="forms_checkbox">
              <input type="checkbox" id="termsq_checkbox" name="termsq_checkbox" class="hide" v-model="center.termsQ" />
              <label for="termsq_checkbox"
                ><i class="icon"></i>개인정보 수집 및 이용에 동의합니다. (문의 응대 외의 용도로는 사용되지
                않습니다.)</label
              >
            </span>
            <button type="button" class="terms_btn" @click="viewTerms">보기</button>
          </div>
        </template>
        <template v-slot:button>
          <SubmitButton
            :btnObj="{ class: 'submit', text: '문의하기' }"
            :disable="checkHasData"
            @btnEvt="centerSubmit"
          />
        </template>
      </CenterSubmit>
    </section>
  </div>
</template>

<script>
import SubmitButton from "@/components/SubmitButton.vue"
import LayerPopup from "@/components/LayerPopup.vue"
import CenterSubmit from "@/components/Center/CenterSubmit"
import constants from "@/service/constant/store"
import { mapState, mapGetters } from "vuex"

export default {
  name: "Center",
  components: {
    SubmitButton,
    LayerPopup,
    CenterSubmit,
  },
  data() {
    return {
      terms: false,
    }
  },
  destroyed() {
    this.$store.commit(constants.RESET_CENTER_DATA)
  },
  computed: {
    ...mapState(["center"]),
    ...mapGetters(["contsCount", "checkHasData"]),
  },
  methods: {
    viewTerms() {
      this.terms = !this.terms
    },
    validEmail() {
      const re = /.+@.+\..+/
      return re.test(this.center.Email)
    },
    insertLengthCheck(value, limit, commitMsg) {
      const validResult = this.$validateRegExp(value, limit)
      this.$store.commit(constants[commitMsg], validResult)
    },
    async centerSubmit() {
      if (this.center.PNumber.length < 10) {
        alert("연락처를 정확하게 입력해 주십시오.")
        return false
      }
      if (!this.validEmail()) {
        alert("Email 형식이 올바르지 않습니다.")
        return false
      }
      if (this.center.Title.length < 2) {
        alert("제목을 2자 이상 입력해 주십시오.")
        return false
      }
      if (this.center.Contents.length < 10) {
        alert("내용을 10자 이상 입력해 주십시오.")
        return false
      }

      const httpData = {}
      httpData.Header = {
        CmdType: "010001",
        CallApp: "www",
        TransactionID: this.$guid(),
      }
      httpData.Body = {
        QnAType: this.center.caseQ,
        UName: this.center.PName,
        PNumber: this.center.PNumber,
        Email: this.center.Email,
        Title: this.center.Title,
        Contents: this.center.Contents,
        AgreeQnA: this.center.termsQ ? "1" : "0",
      }

      this.$store.commit(constants.IS_LOADING)
      try {
        const { data } = await this.$SRSServer(httpData)
        if (data.Header.ResultCd != "00000000") {
          alert(data.Header.ResultMsg)
        } else {
          alert("문의가 접수되었습니다")
          this.$store.commit(constants.RESET_CENTER_DATA)
          this.$router.push("/home")
        }
      } catch (error) {
        console.log(error)
      } finally {
        this.$store.commit(constants.IS_LOADING)
      }
    },
  },
}
</script>
